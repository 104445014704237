import axios from 'axios';
// import convertKeysToCamel from '../helper/convertKeysToCamel';
// import convertCamelKeysToUnderscore from '../helper/convertKeysToUnderscore';

function saveBlob(blob) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.target = '_blank';
  a.dispatchEvent(new MouseEvent('click'));
}

export default function Http(store, router) {
  const service = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    responseType: 'json',
  });
  service.interceptors.request.use((config) => {
    if (store.getters['user/token'] !== '') {
      /* eslint no-param-reassign: "error" */
      config.headers.Authorization = `Bearer ${store.getters['user/token']}`;
    }

    if (config.headers.Accept === 'application/pdf') {
      config.responseType = 'blob';
    }
    // eslint-disable-next-line max-len
    // if (config.data) {
    //   console.log('http request2', config.data, convertCamelKeysToUnderscore(config.data));
    // }
    return config;
  });

  service.interceptors.response.use(
    // eslint-disable-next-line
    (response) => {
      let saveAsBlob = false;
      try {
        saveAsBlob = response.config.params?.download !== undefined;
      } catch (e) {
        //
      }
      // console.log('http response', response.data, convertKeysToCamel(response.data));
      if (saveAsBlob) {
        saveBlob(response.data);
      }
      return response;
    },
    (error) => {
      // console.log('https.js', error.response);
      if (typeof error.response !== 'undefined' && error.response.status === 401) {
        router.push('/logout');
        store.dispatch('notificationbar/showNotification', { msg: 'Invalid login details.', color: 'error', show: true });
      } else if (typeof error.response !== 'undefined' && error.response.status === 403) {
        store.dispatch('notificationbar/showNotification', { msg: 'The request was not allowed.', color: 'error', show: true });
      } else if (typeof error.response !== 'undefined' && error.response.status === 404) {
        // router.push('/404');
        if (error.response.data === 'Folder not found' || error.response.data === 'File not found') {
          store.dispatch('notificationbar/showNotification', { msg: error.response.data, color: 'error', show: true });
        } else {
          store.dispatch('notificationbar/showNotification', { msg: 'The request was not found.', color: 'error', show: true });
        }
        // eslint-disable-next-line max-len
      } else if (error.response.status === 400 && Array.isArray(error.response.data) && error.response.data.length > 0) {
        const message = error.response.data.join(', ');
        store.dispatch('notificationbar/showNotification', { msg: message, color: 'error', show: true });
      } else if (error.response.status === 400 && Array.isArray(error.response.data.errors.errors)
        && error.response.data.errors.errors.length > 0) {
        const message = error.response.data.errors.errors.join(', ');
        store.dispatch('notificationbar/showNotification', { msg: message, color: 'error', show: true });
      } if (typeof error.response !== 'undefined' && error.response.status === 400) {
        if (Array.isArray(error.response.data)
          && error.response.data[0].errors
          && error.response.data[0].errors.children) {
          const childrenErrors = error.response.data[0].errors.children;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in childrenErrors) {
            if (childrenErrors[key].errors
              && Array.isArray(childrenErrors[key].errors)
              && childrenErrors[key].errors.length > 0) {
              const errorMessage = childrenErrors[key].errors.join(', ');
              store.dispatch('notificationbar/showNotification', { msg: errorMessage, color: 'error', show: true });
            }
          }
        }
      } else if (typeof error.response !== 'undefined' && typeof error.response.data.message !== 'undefined') {
        store.dispatch('notificationbar/showNotification', { msg: error.response.data.message, color: 'error', show: true });
      } else if (error.response.status === 406) {
        router.push('/logout');
      } else if (error.response.status === 404) {
        store.dispatch('notificationbar/showNotification', { msg: 'Image not found', color: 'error', show: true });
      } else {
        store.dispatch('notificationbar/showNotification', { msg: `Unknown error. Please contact the administrator: ${error.response.detail}`, color: 'error', show: true });
      }
      return Promise.reject(error);
    },
  );

  return service;
}
